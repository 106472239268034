import React from "react";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import StSideOption from "./StSideOption";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteCoursesAPI } from "../../../API/admin/AdminAPI";

const CoursesTableRow = ({ course }) => {
  // const [showModal, setShowModal] = useState(false);
  // const openModal = () => {
  //   setShowModal(!showModal);
  // };
  // const closeModal = () => {
  //   setShowModal(false);
  // };
  const handleDeleteCourse = async () => {
    try {
      const response = await deleteCoursesAPI(course?._id);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr key={course?._id}>
      <td className="border border-1 border-solid text-left text-sm p-[8px] capitalize">
        {course.course}
      </td>
      <td className="border border-1 border-solid text-left text-sm p-[8px]">
        {course.duration}
      </td>
      <td className="border border-1 border-solid text-left text-sm p-[8px]">
        {course.fees}
      </td>
      <td className="border border-1 border-solid text-left text-sm p-[8px]">
        {course.applicationFees}
      </td>
      <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
        <button class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center">
          <EditIcon />
        </button>
        <button
          onClick={handleDeleteCourse}
          class="ml-2 bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center"
        >
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
};

export default CoursesTableRow;
