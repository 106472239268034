import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import AgentCommission from "../../../pages/AdminPages/Agents/AgentCommission";
// import AgentComTableRow from "../../../pages/AdminPages/Agents/AgentComTableRow";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CommissionPresetCompo from "../CommissionPre/CommissionPresetCompo";
import { useNavigate } from "react-router-dom";
import { deleteAgentAPI } from "../../../API/admin/AdminAPI";
import AssignAgentToRepCollege from "../AssignAgentToRep/AssignAgentToRepCollege";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const AgentPro = ({ agentData, setIsEditAgent,handleCertificateCloseModal,setIsUploadCertificate }) => {
  const navigate = useNavigate();
  // console.log(agentData);
  const handleDeleteAgent = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Agent?"
    );

    if (confirmed) {
      const response = await deleteAgentAPI(agentData?._id);
      if (response.status) {
        alert("deleted");
        navigate("/admin/agents");
      } else {
        alert("Something went wrong");
      }
    }
  };
  return (
    <div>
      <div className="flex justify-between gap-x-[80px] sm:gap-x-[70px] md:gap-x-[800px]">
        <div className="md:flex gap-3 justify-between w-full">
          <h4 className="font-bold text-[12px] md:text-[20px] capitalize">
            Agent
            </h4>
          <div className="md:flex gap-2 ">
            <div
              onClick={() => setIsUploadCertificate(true)}
              className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
            >
              <UploadFileOutlinedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              />
            </div>
            <div
              onClick={() => setIsEditAgent(true)}
              className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
            >
              <BorderColorRoundedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              />
            </div>
            <div
              onClick={handleDeleteAgent}
              className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group"
            >
              <DeleteRoundedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          <div className="flex gap-x-[10px] pb-[15px] items-center">
            <div className="text-[20px]">
              <HomeIcon />
            </div>
            <div>
              <p className="text-gray-400">
                {agentData?.address?.state},{agentData?.address?.country}
              </p>
            </div>
          </div>
          <div className="flex gap-x-[10px] pb-[15px] items-center">
            <div className="text-[20px]">
              <EmailIcon />
            </div>
            <div>
              <p className="text-gray-400">{agentData?.email}</p>
            </div>
          </div>
          <div className="flex gap-x-[15px]">
            <div className="flex gap-x-[10px]">
              <div className="text-[20px]">
                <LocalPhoneIcon />
              </div>
              <div>
                <p className="text-gray-400">{agentData?.phone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {agentData.isRepOrCollegeExist ? (
          <div>
            <CommissionPresetCompo personData={agentData} />
          </div>
        ) : (
          <div>
            <AssignAgentToRepCollege agentData={agentData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentPro;
