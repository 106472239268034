import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditCommission from "../CommissionInputs/EditCommission";
import EditStudent from "../EditForms.jsx/EditStudent";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import FlagIcon from "@mui/icons-material/Flag";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import StudentDocs from "../StudentDocGeneration/StudentDocs";

const StudentProfile = ({ studentData, commission, handleRefresh }) => {
  const [commissionData, setCommissionData] = useState({});
  const [editStudentModal, setEditStudentModal] = useState(false);

  useEffect(() => {
    setCommissionData(commission);
  }, [commission]);

  return (
    <>
      <div className="relative">
        <div>
          <div
            className={`flex justify-between gap-x-[80px] sm:gap-x-[70px] md:gap-x-[800px] ${
              editStudentModal && `blur-sm`
            }`}
          >
            <div>
              <h4 className="font-bold">Student</h4>
            </div>
            <div className="md:flex gap-2">
              <div
                onClick={() => setEditStudentModal(true)}
                className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
              >
                <BorderColorRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div>
              <div
                // onClick={handleDeleteCollege}
                className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group"
              >
                <DeleteRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <HomeIcon />
              </div>
              <div>
                <p className="text-gray-800">
                  {studentData?.address?.city},{studentData?.address?.state}
                </p>
              </div>
            </div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <FlagIcon />
              </div>
              <div>
                <p className="text-gray-800">{studentData?.address?.country}</p>
              </div>
            </div>
            <div className="flex gap-x-[15px] ">
              <div className="flex items-center ">
                <div className="text-[20px]">
                  <AttachEmailIcon />
                </div>
                <div>
                  <p className="text-gray-800 px-2">{studentData?.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-[150px] flex-col md:flex-row mt-5">
            <div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400">Representative</p>
                {studentData?.representative ? (
                  <p className="font-semibold text-[17px] capitalize">
                    {studentData?.representative?.name}
                  </p>
                ) : (
                  <p className="font-semibold text-[14px] capitalize text-red-600">
                    No Representatives
                  </p>
                )}
              </div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400 ">Agent</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.agent?.name}
                </p>
              </div>
            </div>
            <div className="border-l-2 border-gray-400   hidden md:block" />
            <div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400">College</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.college?.name}
                </p>
              </div>
              <div className="pb-[10px]">
                <p className="text-[15px] text-gray-400 capitalize">Course</p>
                <p className="font-semibold text-[17px] capitalize">
                  {studentData?.course?.name}
                </p>
              </div>
            </div>
          </div>
          {/* {commissionData?.status === "pending" ? (
            <div>
              <EditCommission commissionData={commissionData} />
            </div>
          ) : (
            <>
              <div>
                <div className="flex items-center py-4">
                  <div className="bg-white max-w-md w-full p-8 shadow-md shadow-gray-300">
                    <div className="text-center">
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <CheckCircleIcon className="h-6 w-6 text-green-600" />
                      </div>
                      <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900 ">
                        Payment Completed!
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
        </div>
        {/* Student document section here */}
        <div>
          <StudentDocs studentData={studentData}/>
        </div>
        {editStudentModal && (
          <div className="absolute top-0 z-10 w-full">
            <EditStudent
              collegeData={studentData.college}
              setEditStudentModal={setEditStudentModal}
              // closeModal={closeModal}
              student={studentData}
              handleRefresh={handleRefresh}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StudentProfile;
