import React, { useRef } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
// import OfferLetter from "../../../pages/Pdf Templates/offerLetter";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const OfferLetterModal = ({ handelOfferLetterModal }) => {
  //   function handleDownloadButtonClick() {
  //     const content = divRef.current.innerHTML;
  //     const docDefinition = {
  //       content: [{ text: content, style: "header" }],
  //       styles: {
  //         header: {
  //           fontSize: 18,
  //           bold: true,
  //         },
  //       },
  //     };
  //     pdfMake.createPdf(docDefinition).download("document.pdf");
  //   }

  const divRef = useRef();
  console.log(divRef, "divRef");
  const handlePrint = () => {
    const printContent = divRef.current;
    const windowPrint = window.open("", "", "width=600,height=600");
    windowPrint.document.write("<html><head><title>Print</title>");
    windowPrint.document.write("</head><body>");
    windowPrint.document.write(printContent.outerHTML);
    windowPrint.document.write("</body></html>");
    windowPrint.document.close();
    windowPrint.print();
  };

  // Function to extract text content from HTML
  //   const extractTextFromHtml = (html) => {
  //     const tempDiv = document.createElement("div");
  //     tempDiv.innerHTML = html;
  //     return tempDiv.textContent || tempDiv.innerText || "";
  //   };

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto top-16">
        <div className="flex items-center justify-center min-h-full px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full sm:my-8 sm:p-6">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <div className="mt-2">
                    <div id="offerletter">
                      {/* offerletter */}
                      <div
                        className="w-[210mm]  bg-white "
                        id="printableContent"
                        ref={divRef}
                      >
                        {/* header */}
                        <div className="flex gap-1 justify-center mt-2">
                          <div className="mt-5">
                            <img
                              src="/assets/img/Acetek_logo.jpg"
                              alt="Acetek Logo"
                              width={140}
                            />
                          </div>

                          <div>
                            <div className="flex gap-20">
                              <div className="text-[9px] text-[#2C3178] mt-5">
                                cpe Registration No: 200821327E <br />
                                (21 June 2023 to 20 June 2027) <br />
                                EduTrust Certificate No: EDU-3-3198 (29 January
                                2024 to 28 January 2025)
                              </div>
                              <div className="flex-col flex">
                                <span className="text-[#2C3178] font-bold text-[17px] flex justify-end">
                                  AceTek College Pte Ltd
                                </span>
                                <div className="flex justify-end">
                                  <span className="text-[9px] text-[#2C3178] flex-col">
                                    <span className="flex justify-end">
                                      Blk 167 Jalan Bukit Merah, #02-12
                                    </span>
                                    Connection One Tower 5, Singapore 150167
                                  </span>
                                </div>
                                <div className="flex gap-2">
                                  {/* phone icon */}
                                  <div className="flex items-center gap-1">
                                    <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                                      <LocalPhoneIcon
                                        className="text-white"
                                        style={{ fontSize: 8 }}
                                      />
                                    </div>
                                    <span className="text-[9px] text-[#2C3178]">
                                      (65) 6970 1370
                                    </span>
                                  </div>
                                  {/* email icon */}
                                  <div className="flex items-center gap-1">
                                    <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                                      <EmailIcon
                                        className="text-white"
                                        style={{ fontSize: 8 }}
                                      />
                                    </div>
                                    <span className="text-[9px] text-[#2C3178]">
                                      info@acetech.edu.sh
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-yellow-500 w-full h-1 mt-1"></div>
                          </div>
                        </div>

                        {/* body */}
                        <div className="m-10 text-[12px]">
                          <div className="text-[12px] flex justify-between">
                            <span>02/05/2024</span>
                            <span>Ref: ATC/CLOO/24/05/007</span>
                          </div>
                          <div className="mt-4 flex flex-col gap-4">
                            <span>
                              Dear Ms. HAPUHINNE GEDARA MANEESHA LAKSHMI UDARI{" "}
                              <br />
                              Passport No: N10387052 <br />
                              SRI LANKAN <br />
                              <span className="font-semibold ">
                                CONDITIONAL LETTER OF OFFER FOR DIPLOMA IN
                                HOSPITALITY MANAGEMENT
                              </span>
                            </span>
                            <span>
                              Thank you for your interest in the above-mentioned
                              course.
                            </span>

                            <span className="me-10 flex flex-col gap-1">
                              With reference to your documents submitted for
                              review, we are pleased to inform you that you have
                              met the minimum entry requirements for the above
                              course and are eligible to apply for admission.
                              Kindly complete and submit the documents (with a
                              certified translation in the English Language if
                              necessary) listed in the attached admission
                              checklist for application purposes. If there is no
                              response from you within two weeks from the date
                              of this letter, we will treat this application as
                              void.
                              <br />
                              <span className="font-semibold">
                                Details of the above course are as follows:
                              </span>
                            </span>
                          </div>
                          <div className="mt-6">
                            <div className="flex flex-col">
                              <table className="w-full border-collapse">
                                <tbody>
                                  <tr className="mb-4">
                                    <td className="pb-3 pr-3 text-left">
                                      Course:
                                    </td>
                                    <td className="pb-4 text-left">
                                      <span className="font-bold">
                                        DIPLOMA IN HOSPITALITY MANAGEMENT
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pb-4 pr-4 text-left w-48 flex">
                                      Commencement Date:
                                    </td>
                                    <td className="pb-4 text-left whitespace-nowrap">
                                      18/06/2024
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pb-8 pr-4 text-left">
                                      Duration of Course:
                                    </td>
                                    <td className="text-left pt-6">
                                      12 months (6 months of Institutional
                                      Training + 6 months of
                                      <br />
                                      industrial attachment).
                                      <br />
                                      The Industrial Attachment is subject to
                                      the approval and issuance of
                                      <br />a Training Work Permit by the
                                      Ministry of Manpower, Singapore.
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pb-7 pr-4 text-left">
                                      Application Fee:
                                    </td>
                                    <td className="pb-4 text-left pt-8">
                                      S$109.00 inclusive of 9% GST
                                      <br />
                                      (payable upon application
                                      <br />
                                      nonrefundable fees)
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pb-10 pr-4 text-left">
                                      Course Fee:
                                    </td>
                                    <td className="pb-4 text-left pt-4">
                                      <span className="font-semibold">
                                        S$4,360.00
                                      </span>{" "}
                                      inclusive of 9% GST <br /> Course Fee is
                                      payable after signing the Student Contract
                                      between <br /> you and AceTek.
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pb-1 pt-2 pr-4 text-left">
                                      Award:
                                    </td>
                                    <td className="pb-4 text-left pt-5">
                                      DIPLOMA IN HOSPITALITY MANAGEMENT
                                    </td>
                                  </tr>
                                  <tr className="mb-4">
                                    <td className="pt-2 pr-4 text-left">
                                      Awarding Body:
                                    </td>
                                    <td className="pb-4 text-left pt-5">
                                      AceTek College
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <span className="mt-7">
                                Please email info@acetek.edu.sg if you have any
                                questions.
                              </span>
                              <br />
                              <span>Yours sincerely</span>
                              <br />
                              <span className="mt-11">
                                Sudeep Sreedharan
                                <br />
                                Managing Director
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* close body */}
                      </div>

                      {/* offerletter */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handelOfferLetterModal}
              >
                Close
              </button>
              <button
                type=""
                className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handlePrint}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferLetterModal;
