import React, { Fragment, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { editRepCommissionAPI } from "../../../API/admin/AdminAPI";

const RepCommissionTableRow = ({ item, data, setData }) => {
  const [isEditField, setIsEditField] = useState(false);
  const [commissionInput, setCommissionInput] = useState(item.commission);
  const [open, setOpen] = React.useState(false);
  const handleEditClick = (id) => {
    setIsEditField(!isEditField);
  };
  const handleSaveClick = async (id) => {
    const createBody = {
      courseId: item.courseId,
      course: item.course,
      changedCommission: commissionInput,
      repId: data._id,
    };
    const response = await editRepCommissionAPI(createBody);
    if (response.status === 200) {
      setIsEditField(false);
      handleClick();
    } else {
      console.log("error");
    }
  };

  const handleInputChange = (e) => {
    setCommissionInput(e.target.value);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );
  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Commission successfully saved!
        </Alert>
      </Snackbar>
      <tr key={item.id} className="text-gray-700">
        <td className="py-3 px-4">{item.course}</td>
        <td className="py-3 px-4">{item.courseFee}</td>
        <td className="py-3 px-4">
          <input
            type="text"
            className=" border rounded  px-3 text-sm py-1"
            value={commissionInput}
            onChange={handleInputChange}
            readOnly={!isEditField}
          />
          <button
            className="ml-1 border-2 border-gray-300 text-gray-400  py-1 px-1 rounded hover:bg-gray-600 hover:text-white text-sm font-semibold"
            onClick={() => handleEditClick(item.id)}
          >
            <EditIcon sx={{ fontSize: 15 }} />
          </button>
        </td>
        <td className="py-3 px-4">
          <button
            className="bg-green-500 text-white px-4 py-1 rounded hover:bg-blue-600  text-sm font-semibold"
            onClick={() => handleSaveClick(item.id)}
          >
            Save
          </button>
        </td>
      </tr>
    </>
  );
};

export default RepCommissionTableRow;
