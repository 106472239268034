import React, { useState } from "react";
import { addCoursesAPI } from "../../../API/admin/AdminAPI";
import Loader from "./Loader";

const AddCourses = ({
  setIsAddCourse,
  collegeData,
  courseData,
  setCourseData,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({
    college: collegeData._id,
    course: "",
    duration: "",
    applicationFees: "",
    fees: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (formData.course.trim() === "") {
      errors.course = "Course is required";
    }
    if (formData.duration.trim() === "") {
      errors.duration = "Duration is required";
    }
    if (formData.applicationFees.trim() === "") {
      errors.applicationFees = "applicationFees is required";
    }
    if (formData.fees.trim() === "") {
      errors.fees = "Fees is required";
    }
    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoader(true);
      try {
        const response = await addCoursesAPI(formData);
        if (response?.status === 201) {
          setIsLoader(false);
          // Reset form after successful submission
          setFormData({
            college: collegeData._id,
            course: "",
            duration: "",
            intake: "",
            fees: "",
          });
          setCourseData([...courseData, response?.data]);
          setIsAddCourse(false);
        }
        // else if (response.status === 409) {
        //   // setCollegeExist(true);
        // }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFormErrors(errors);
    }
  };
  return (
    <div className="flex justify-center md:flex items-center py-[20px]">
      <div className=" w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[30px] md:text-left flex py-1">
            <p className="font-bold border-b-2 border-gray-400">Add Course</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 my-[10px]">
            <div>
              <label className="block mb-2 font-bold" htmlFor="fullName">
                Course:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="course"
                name="course"
                placeholder="course"
                value={formData.course}
                onChange={handleInputChange}
                required
              />
              {formErrors.course && (
                <span className="text-xs text-red-600">
                  {formErrors.course}
                </span>
              )}
            </div>
            <div>
              <label className="block mb-2 font-bold" htmlFor="Duration">
                {" "}
                Duration:
              </label>
              {/* college */}
              <input
                type="text"
                className="block w-full px-4 py-2 md:mt-[10px] border border-gray-300 rounded-lg text-gray-500"
                id="duration"
                name="duration"
                placeholder="Duration"
                value={formData.duration}
                onChange={handleInputChange}
                required
              />
              {formErrors.duration && (
                <span className="text-xs text-red-600">
                  {formErrors.duration}
                </span>
              )}
            </div>

            <div>
              <label className="block mb-2 font-bold" htmlFor="Fees">
                Course fees:
              </label>
              <input
                className="block my-[10px] w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fees"
                name="fees"
                placeholder={2000}
                value={formData.fees}
                onChange={handleInputChange}
                required
              />
              {formErrors.fees && (
                <span className="text-xs text-red-600">{formErrors.fees}</span>
              )}
            </div>
            <div>
              <label className="block mb-2 font-bold" htmlFor="Fees">
                Application fees:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="applicationFees"
                name="applicationFees"
                placeholder={2000}
                value={formData.applicationFees}
                onChange={handleInputChange}
                required
              />
              {formErrors.applicationFees && (
                <span className="text-xs text-red-600">
                  {formErrors.applicationFees}
                </span>
              )}
            </div>
          </div>
          {/* section six */}
          <div className="flex justify-end gap-x-[20px]">
            <button
              onClick={() => setIsAddCourse(false)}
              className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded"
              type="submit"
            >
              cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded"
              type="submit"
              disabled={isLoader}
            >
              {!isLoader ? <span>Save</span> : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourses;
