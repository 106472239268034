import React from "react";
import { Link } from "react-router-dom";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import StSideOption from "./StSideOption";
// import EditStudent from "../EditForms.jsx/EditStudent";

const StudentTableRow = ({ student, collegeData }) => {
  // const [showModal, setShowModal] = useState(false);
  // const [editStudentModal, setEditStudentModal] = useState(false);

  // const openModal = () => {
  //   setShowModal(!showModal);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  return (
    <>
      <tr>
        <td className="border border-1 border-solid text-left text-sm p-[8px] hover:underline capitalize">
          <Link
            to={`/admin/student/${student?.name}`}
            state={{ studentId: student?._id }}
          >
            {student.name}
          </Link>
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {student?.address?.country}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {student?.fees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {student?.balancedFees}
        </td>
        {/* <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          <button onClick={openModal} className="">
            <MoreHorizIcon
              className={showModal && "text-red-400"}
              sx={showModal ? { fontSize: 40 } : { fontSize: 35 }}
            />
          </button>
          <div className="relative">
            {showModal && (
              <StSideOption
                student={student}
                closeModal={closeModal}
                setEditStudentModal={setEditStudentModal}
              />
            )}
          </div>
        </td> */}
        <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          ...
        </td>
      </tr>
      {/* {editStudentModal && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto top-24">
            <div className="flex items-center justify-center h-full  px-4">
              <div
                className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <EditStudent
                collegeData={collegeData}
                setEditStudentModal={setEditStudentModal}
                closeModal={closeModal}
                student={student}
              />
            </div>
          </div>
        </>
      )} */}
    </>
  );
};

export default StudentTableRow;
