import { API } from "../axios";

export const fetchStudentOfferLetterDataAPI = async (studentId) => {
    try {
        const { data, status } = await API.get(`/admin/generate-pdf-templates/student-offer-letter/${studentId}`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};

export const fetchStudentContractDetails = async (studentId) => {
    try {
        const { data, status } = await API.get(`/admin/generate-pdf-templates/student-pre-contract-letter/${studentId}`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};
export const fetchStudentLetterOfOfferDataAPI = async (studentId) =>{
    try {
        const { data, status } = await API.get(`/admin/generate-pdf-templates/student-letter-of-offer/${studentId}`);
        return { data, status };

        
    } catch (error) {
        return error?.response;

        
    }
}

export const fetchStudentInvoiceAPI = async (paymentId) => {
    try {
        const { data, status } = await API.get(`/admin/generate-pdf-templates/student-payment-invoice/${paymentId}`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};