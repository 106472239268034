import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useLocation, useParams } from "react-router-dom";
import { fetchStudentLetterOfOfferDataAPI } from "../../API/admin/GenerateStudentDocuments";

function LetterOfOffer() {
  const handleDownload = () => {
    window.print();
  };
  const [studentOfferOfLetterData, setStudentOfferOfLetterData] = useState({});
  // const studentId = useParams();
    const { studentId } = useParams();
    console.log(studentId,'studentId');
    const fetchStudentLetterOfOffer = async () => {
      try {
        const response = await fetchStudentLetterOfOfferDataAPI(studentId);
        console.log(response);
        if (response.status === 200) {
            setStudentOfferOfLetterData(response.data);
          document.title = `${response?.data?.name}-conditional-offer-letter`;
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      fetchStudentLetterOfOffer();
    }, []);

  // Create a new Date object representing the current date and time
  const today = new Date();

  // Extract the day, month, and year from the Date object
  const day = String(today.getDate()).padStart(2, "0"); // Day of the month (1-31), padded to 2 digits
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad to 2 digits
  const year = today.getFullYear(); // Full year (e.g., 2024)

  // Construct the date string in the desired format
  const formattedDate = `${day}/${month}/${year}`;

  return (
    <>
      {Object.keys(studentOfferOfLetterData).length > 0 ? (
      <>
        <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
          <button
            type=""
            className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
        <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
          {/* offerletter */}
          <div className="w-[210mm]  bg-white " id="offerLetter">
            {/* header */}
            <div className="flex gap-1 justify-center mt-2">
              <div className="mt-5">
                <img
                  src="/assets/img/Acetek_logo.jpg"
                  alt="Acetek Logo"
                  width={140}
                />
              </div>

              <div>
                <div className="flex gap-20">
                  <div className="text-[9px] text-[#2C3178] mt-5">
                    cpe Registration No: 200821327E <br />
                    (21 June 2023 to 20 June 2027) <br />
                    EduTrust Certificate No: EDU-3-3198 (29 January 2024 to 28
                    January 2025)
                  </div>
                  <div className="flex-col flex">
                    <span className="text-[#2C3178] font-bold text-[17px] flex justify-end">
                      AceTek College Pte Ltd
                    </span>
                    <div className="flex justify-end">
                      <span className="text-[9px] text-[#2C3178] flex-col">
                        <span className="flex justify-end">
                          Blk 167 Jalan Bukit Merah, #02-12
                        </span>
                        Connection One Tower 5, Singapore 150167
                      </span>
                    </div>
                    <div className="flex gap-2">
                      {/* phone icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <LocalPhoneIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          (65) 6970 1370
                        </span>
                      </div>
                      {/* email icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <EmailIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          info@acetech.edu.sh
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-yellow-500 w-full h-1 mt-1"></div>
              </div>
            </div>

            {/* body */}
            <div className=" m-10 text-[13px]">
              <div className="text-[12px] flex justify-between">
                <span>{formattedDate}</span>
                <span>Ref: ATC/LOO/24/05/070</span>
              </div>
              <div className="flex flex-col mt-2 gap-4">
                <span>
                  <span className="font-bold">
                    MR.{studentOfferOfLetterData?.name}  <br />
                    PASSPORT NO: {
                      studentOfferOfLetterData?.passport
                    }  <br />
                    {/* {studentOfferLetterData?.country} <br /> */}
                    <span className="font-normal">
                      NO 13 KABARAYE STREET MANDALAY TOWNSHIP <br />
                      MYANMAR
                    </span>
                  </span>
                </span>
                <span>Dear Mr.{studentOfferOfLetterData?.name}</span>

                <span className="font-semibold">
                  Letter of Offer for DIPLOMA IN BAKING TECHNOLOGY
                </span>
                <span>
                  Thank you for your application to join the above-named course.
                  We are pleased to inform you that your application documents
                  are in order and that a place has been reserved for you based
                  on your application and supporting documents submitted.{" "}
                </span>

                <span>
                  This Letter of Offer for your application to enrol in the said
                  course is subject to the clearance of all formalities by the
                  Immigration and Checkpoints Authority (ICA) of Singapore and
                  upon successful issuance of the Student Pass to you
                </span>
                <span>
                  You are encouraged to bring your own laptop/tablet to complete
                  and submit your course work in a timely manner
                </span>
                <span>
                  The next step is to accept the offer by signing below. If
                  there is no response from you within two weeks of <br />
                  <span className="font-bold">
                    {" "}
                    the date of this letter, we will treat this application as
                    void.{" "}
                  </span>
                </span>
                <span className="font-bold">
                  The following are provided for your information:{" "}
                </span>
              </div>
              <div className="mt-6">
                <div className="flex flex-col">
                  <table className="w-full border-collapse">
                    <tbody>
                      <tr className="mb-4">
                        <td className="pb-3 pr-3 text-left">Course:</td>
                        <td className="pb-4 text-left">
                          <span className="font-bold  uppercase">
                            {studentOfferOfLetterData?.course}
                          </span>
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-4 pr-4 text-left w-48 flex">
                          Commencement Date:
                        </td>
                        <td className="pb-4 text-left whitespace-nowrap">
                          {/* {studentOfferLetterData?.commencementDate}  */}
                          18/06/2024
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-36 pr-4 text-left">
                          Duration of Course:
                        </td>
                        <td className="text-left pt-6">
                          {studentOfferOfLetterData?.durationOfCourse}12 months (6
                          months of institutional training+ 6 months of
                          <br />
                          industrial attachment).
                          <br />
                          Participation in an industrial attachment is mandatory
                          and forms an essential component of the course.
                          <br />
                          <p className="mt-5">
                            The Industrial Attachment is subject to the approval
                            and issuance of Training Work Permit by Ministry of
                            Manpower, Singapore. In the event that a student is
                            unable to secure a suitable internship due to
                            circumstances beyond the college’s control, the
                            alternative requirement will be the completion of a
                            Project Work (Project in lieu of IA will be of 3
                            months duration).
                          </p>
                        </td>
                      </tr>
                      <tr className="mb-4 ">
                        <td className=" mt-3  pr-4 text-left">
                          Application Fee:
                        </td>
                        <td className=" text-left pt-3  ">
                          <span className="font-bold">S$109.00 </span> inclusive
                          of 9% GST (payable upon application)
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-12 pr-4 text-left">Course Fee:</td>
                        <td className=" text-left pt-4">
                          <span className="font-semibold">S$5,450.00 </span>{" "}
                          inclusive of 9% GST (payable in --- 1 instalment){" "}
                          <br />
                          <span className="">
                            <div className="mt-4">
                              Course Fee is payable after Successful application
                              of the Student pass issued by Immigration &
                              Checkpoints Authority, Singapore, and the signing
                              of the Student Contract between you and AceTek
                              College
                            </div>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end text-slate-500 text-xs">Page 1|3</div>
              </div>
            </div>

            {/* //NextPage */}
            {/* header */}
            <div className="flex gap-1 justify-center mt-2">
              <div className="mt-5">
                <img
                  src="/assets/img/Acetek_logo.jpg"
                  alt="Acetek Logo"
                  width={140}
                />
              </div>

              <div>
                <div className="flex gap-20">
                  <div className="text-[9px] text-[#2C3178] mt-5">
                    cpe Registration No: 200821327E <br />
                    (21 June 2023 to 20 June 2027) <br />
                    EduTrust Certificate No: EDU-3-3198 (29 January 2024 to 28
                    January 2025)
                  </div>
                  <div className="flex-col flex">
                    <span className="text-[#2C3178] font-bold text-[17px] flex justify-end">
                      AceTek College Pte Ltd
                    </span>
                    <div className="flex justify-end">
                      <span className="text-[9px] text-[#2C3178] flex-col">
                        <span className="flex justify-end">
                          Blk 167 Jalan Bukit Merah, #02-12
                        </span>
                        Connection One Tower 5, Singapore 150167
                      </span>
                    </div>
                    <div className="flex gap-2">
                      {/* phone icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <LocalPhoneIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          (65) 6970 1370
                        </span>
                      </div>
                      {/* email icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <EmailIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          info@acetech.edu.sh
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-yellow-500 w-full h-1 mt-1"></div>
              </div>
            </div>

            {/* body */}

            <div className="m-10 text-[13px]">
              <div className="flex flex-col">
                <table>
                  <tbody>
                    <tr className="mb-4">
                      <td className="pb-1 pt-2 pr-4 text-left">Award:</td>
                      <td className="pb-4 text-left pt-5">
                        DIPLOMA IN HOSPITALITY MANAGEMENT
                      </td>
                    </tr>
                    <tr className="mb-4">
                      <td className="pt-2 pr-20 text-left">Awarding Body:</td>
                      <td className="pb-4 text-left pt-5">AceTek College</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mt-10">
                  <span className="font-bold">
                    <u> PAYMENT MODE </u>{" "}
                  </span>
                  <br />
                  <span className="mt-11">
                    The student needs to make the Course Fee Payment after
                    signing the Advisory Note and the Standard PEI-Student
                    contract. After the standard PEI-Student Contract is signed,
                    students can choose to pay the fees via Telegraphic Transfer
                    / Bank Transfer.
                  </span>
                  <table>
                    <tbody>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Account Name:
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          ACETEK COLLEGE PTE LTD
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BANK NAME
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          BANK NAME DBS Bank Ltd
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BANK ACCOUNT NUMBER
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          0727605607
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Bank Code
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          7171
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BRANCH CODE
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          072 (Raffles Place)
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Name of Beneficiary Bank
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          DBS Bank
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Address of Beneficiary Bank
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          12 Marina Boulevard, DBS Asia Central, Marina Bay
                          Financial Centre Tower 3, Singapore
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Address of Beneficiary Bank
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          12 Marina Boulevard, DBS Asia Central, Marina Bay
                          Financial Centre Tower 3, Singapore
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          SWIFT Address/Code
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          DBSSSGSG{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <span className="p-1 pt-5">
                  AceTek College Pte Ltd will relentlessly strive to offer you
                  an educational journey that facilitates your learning
                  experience to meet your aspiration and career needs. We look
                  forward to your successful academic and career advancement
                  through participation in our programme and courses.
                </span>

                <span className="pt-10">Yours sincerely</span>

                <span className="pt-32 pb-10">
                  Sudeep Sreedharan <br />
                  Managing Director
                </span>
              </div>
              <div className="flex justify-end text-slate-500 text-xs">Page 2|3</div>

            </div>

            {/* //nextpage */}
            {/* //header */}
            <div className="flex gap-1 justify-center mt-10">
              <div className="mt-5">
                <img
                  src="/assets/img/Acetek_logo.jpg"
                  alt="Acetek Logo"
                  width={140}
                />
              </div>

              <div>
                <div className="flex gap-20">
                  <div className="text-[9px] text-[#2C3178] mt-5">
                    cpe Registration No: 200821327E <br />
                    (21 June 2023 to 20 June 2027) <br />
                    EduTrust Certificate No: EDU-3-3198 (29 January 2024 to 28
                    January 2025)
                  </div>
                  <div className="flex-col flex">
                    <span className="text-[#2C3178] font-bold text-[17px] flex justify-end">
                      AceTek College Pte Ltd
                    </span>
                    <div className="flex justify-end">
                      <span className="text-[9px] text-[#2C3178] flex-col">
                        <span className="flex justify-end">
                          Blk 167 Jalan Bukit Merah, #02-12
                        </span>
                        Connection One Tower 5, Singapore 150167
                      </span>
                    </div>
                    <div className="flex gap-2">
                      {/* phone icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <LocalPhoneIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          (65) 6970 1370
                        </span>
                      </div>
                      {/* email icon */}
                      <div className="flex items-center gap-1">
                        <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                          <EmailIcon
                            className="text-white"
                            style={{ fontSize: 8 }}
                          />
                        </div>
                        <span className="text-[9px] text-[#2C3178]">
                          info@acetech.edu.sh
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-yellow-500 w-full h-1 mt-1"></div>
              </div>
            </div>

            {/* //page */}
            <div className="m-10 text-[13px]">
              <div className="flex flex-col">
                <div className="mt-10 w-full flex flex-col gap-14 p-5">
                  <div className="flex justify-center">
                    <span className="font-bold text-[15px] text-center">
                      <u>ACCEPTANCE OF OFFER</u>{" "}
                    </span>
                  </div>
                  <span className="">
                    Regarding the Offer Letter, Reference No.: <span className="font-semibold">
                    ATC/LOO/24/05/070, </span> I,  <span className="font-semibold">Mr. CHAN MYAE KYAW </span>, holding Myanmar
                    <span className="font-semibold"> Passport No.: MI773663 </span>, hereby confirm my acceptance of the
                    offer extended by AceTek College Pte Ltd for enrollment in
                    the following course:
                  </span>
                  <span className="font-semibold">
                    Course: DIPLOMA IN BAKING TECHNOLOGY
                  </span>

                  <span>
                    I have thoroughly reviewed and agreed to the terms and
                    conditions outlined in your Letter of Offer and Payment
                    Details. I am aware that I will also need to execute a
                    Student's Contract with AceTek College.
                  </span>
                  <span className="flex gap-1">
                    <span className="flex">
                      Signature :{" "}
                      <div className="h-[0.5px] w-[16rem] mt-4 bg-black"></div>
                    </span>
                    <span className="flex">
                      Date:{" "}
                      <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>
                    </span>
                  </span>
                  <span>
                    If the aforementioned individual is under 18 years of age or
                    lacks independent means, their parent/guardian must complete
                    the following declaration:
                  </span>

                  <span className="flex  flex-col">
                   <span className="flex"> I, <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div> 
                    [Full Name and ID No]. of Parent/Guardian,
                    acting as the   </span> 
                    <span className="flex">parent/guardian of <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>[Student's
                    Full Name], agree to the aforementioned </span> terms and conditions
                    on behalf of my child/ward.
                  </span>


                  <span className="flex gap-1">
                    <span className="flex">
                      Signature :{" "}
                      <div className="h-[0.5px] w-[16rem] mt-4 bg-black"></div>
                    </span>
                    <span className="flex">
                      Date:{" "}
                      <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>
                    </span>
                  </span>
                  <div className="flex justify-end text-slate-500 text-xs mt-24 relative">Page 3|3</div>

                </div>

              
              </div>
            </div>
          </div>
        </div>
      </>
     ) : (
        <p>Fetching.....</p>
      )} 
    </>
  );
}

export default LetterOfOffer;
