import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { deleteRepAPI } from "../../../API/admin/AdminAPI";
import { useNavigate } from "react-router-dom";
import RepCommissionTable from "../CommissionPre/RepCommissionTable";

// import RepCommission from "../../../pages/AdminPages/Representative/RepCommission";

const RepProfileCompo = ({ repData, setIsEditRep }) => {
  const navigate = useNavigate();
  const handleDeleteRep = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Representative?"
    );

    if (confirmed) {
      const response = await deleteRepAPI(repData?._id);
      if (response.status) {
        alert("deleted");
        navigate("/admin/representatives");
      } else {
        alert("Something went wrong");
      }
    }
  };
  return (
    <>
      <div>
        <div className="flex justify-between gap-x-[80px] sm:gap-x-[70px] md:gap-x-[800px]">
          <div className="flex justify-between w-full pr-5">
            <h4 className="font-bold text-[12px] md:text-[20px]">
              Representative
            </h4>
            <div className="md:flex gap-2">
              <div
                onClick={() => setIsEditRep(true)}
                className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
              >
                <BorderColorRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div>
              <div
                onClick={handleDeleteRep}
                className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group"
              >
                <DeleteRoundedIcon
                  sx={{ fontSize: 25 }}
                  className="text-black-600 group-hover:text-white transition"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-[px]">
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <HomeIcon />
              </div>
              <div>
                <p className="text-gray-400">
                  {repData?.address?.state},{repData?.address?.country}
                </p>
              </div>
            </div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <EmailIcon />
              </div>
              <div>
                <p className="text-gray-400">{repData?.email}</p>
              </div>
            </div>
            <div className="flex gap-x-[15px]">
              <div className="flex gap-x-[10px]">
                <div className="text-[20px]">
                  <LocalPhoneIcon />
                </div>
                <div>
                  <p className="text-gray-400">{repData?.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RepCommissionTable personData={repData} />
    </>
  );
};

export default RepProfileCompo;
